import crypto from "crypto-js";

export const WEBSITE_NAME = "SuperPay";
export const AUTH_TOKEN = "b8ozhrtwm9fa1qhb1neevsvbmob6tm";
export const API_ROUTE = "https://api.rupeeearn.online/payments/router/";


export function redirectTo(url) {
    window.location.replace(url);
}

export function openNewPage(url) {
    window.location.href =url;
}

export function getURLParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

export function getInternetStatus(){
    if(navigator.onLine){
        return true;
    }else{
        return false;
    }
}

export function copyText(text){

    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.opacity = "0"; 
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    } catch (err) {
      return true;
    }

    return true;
}

export function generateAuthToken(token){
    return crypto.SHA256(token).toString();
}
  